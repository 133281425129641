<template lang="pug">
	.d-flex.flex-column-reverse.flex-sm-row.justify-content-between.align-items-center.position-relative.footer-payment-new(:class="{'border-dark' : isDarkTheme}")
		.d-flex.flex-column
			router-link.position-relative(to="/")
				img.footer-payment-new__logo(src="/assets/img/current/logo-white.svg")
			.footer-payment-new__text(v-html="underLogoText")
		.d-flex.align-items-center
			//- locale-switch-simple
			switches.d-inline-block.ml-4.payment-page__switch#tool-mode-switch(v-model='isDarkTheme')
		a.footer-payment-new__to-tariffs(v-if="isMobile && expand && showButtonToTariffs" href="#payment-page-tariffs") {{ $t('paymentPage.toTariffs') }}
</template>

<script>
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';
import ThemeSwitch from '@/components/Common/ThemeSwitch';
import Switches from 'vue-switches';
import { defaultColor } from '@/constants/config';

export default {
    components: {
        LocaleSwitchSimple,
        ThemeSwitch,
        Switches,
    },
    props: {
        underLogoText: {
            type: String,
            default: '',
        },
        isDarkTheme: {
            type: Boolean,
            default: undefined,
        },
    },
    data: () => ({
        expand: false,
    }),
    methods: {
        getProject() {
            return new Promise((res, rej) => {
                this.hash({ id: this.hashId })
                    .then(v => {
                        if (this.$route.query.test) {
                            this.deb.hash = this.hashId;
                            this.deb.location = window.location;
                            this.deb.browser = navigator.userAgent;
                            this.deb.responce = v;
                            this.deb.updated = '2021-09-30';
                            rej(false);
                            return false;
                        }

                        if (_.isUndefined(v) || !v.id) {
                            rej(false);
                            this.$router.push({ name: '404' });
                            return;
                        }

                        this.$store.commit('project/setOpened', v);

                        import(
                            '@/assets/css/sass/themes/piaf.' + (this.isDarkTheme ? 'dark' : 'light') + '.figma.scss'
                        );

                        if (this.$route.query.rcm)
                            setTimeout(() => {
                                this.rcm = JSON.parse(atob(this.$route.query.rcm.replace(/_/g, '=')));
                                this.openModalPaymentCountry({
                                    plan: _.find(this.item.plans, p => p.id == this.rcm.tid),
                                    isTrial: false,
                                });
                            }, 500);

                        res(true);
                    })
                    .catch(v => {
                        if (this.$route.query.test) {
                            this.deb.hash = this.hashId;
                            this.deb.location = window.location;
                            this.deb.browser = navigator.userAgent;
                            this.deb.error = JSON.stringify(v);
                            this.deb.updated = '2021-09-30';
                        }
                        setTimeout(() => {
                            this.hash({ id: this.hashId })
                                .then(v => {
                                    if (_.isUndefined(v) || !v.id) {
                                        rej(false);
                                        this.$router.push({ name: '404' });
                                        return;
                                    }
                                    this.$store.commit('project/setOpened', v);
                                    res(true);
                                })
                                .catch(v => {
                                    setTimeout(() => {
                                        this.hash({ id: this.hashId })
                                            .then(v => {
                                                if (_.isUndefined(v) || !v.id) {
                                                    rej(false);
                                                    this.$router.push({ name: '404' });
                                                    return;
                                                }
                                                this.$store.commit('project/setOpened', v);
                                                res(true);
                                            })
                                            .catch(v => {
                                                return;
                                            });
                                    }, 100);
                                });
                        }, 100);
                    });
            });
        },
        getThemeColor() {
            return localStorage.getItem('themeColorPaymentPage')
                ? localStorage.getItem('themeColorPaymentPage')
                : defaultColor;
        },
    },

    watch: {
        isDarkTheme(val) {
            let color = this.getThemeColor();
            let isChange = false;

            if (val) {
                isChange = true;
                color = color.replace('light', 'dark');
            } else {
                isChange = true;
                color = color.replace('dark', 'light');
            }

            if (isChange) {
                localStorage.setItem('themeColorPaymentPage', color);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.footer-payment-new {
    padding: 19px 20px 20px;
    margin-top: auto;
    background-color: var(--bg-footer-payment);
    border-top: 1px solid var(--brand-top-border-color);
    border-radius: 0 0 8px 8px;

    &.border-dark {
        border: 1px solid #444;
        border-top: none !important;
    }

    a,
    &__wrap {
        z-index: 1;
    }

    &__logo {
        display: block;
        width: 89px;
        height: 22px;
        margin: 20px auto 8px;

        @media (min-width: 576px) {
            margin: 0 0 8px;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 14px;
        color: white;
    }

    &__rules-text {
        max-width: 388px;
        font-size: 14px;
        line-height: 22px;
        text-align: end;

        a {
            position: relative;
            transition: color var(--animation-time-short);

            &:hover,
            &:active {
                color: var(--brand-primary) !important;
                text-decoration: underline;
            }
        }

        @include respond-below(sm) {
            text-align: center;
        }
    }

    &__switch {
        margin: 0 !important;
    }

    .locale-switch {
        background-color: rgba(white, 0.2);
        margin-right: 17px;
    }

    #tool-mode-switch {
        div {
            background-color: rgba(white, 0.2) !important;

            &:before {
                background-color: #fff;
            }
        }

        &:not(.vue-switcher--unchecked) div:after {
            background-color: #fff !important;
        }
    }
}
</style>
