<template lang="pug">
	.h-100.payment-confirm
		.d-flex.flex-column.h-100(v-if="step == 1")
			.d-flex.align-items-center.payment-confirm__trial(v-if="isTrial")
				.d-flex.justify-content-center.align-items-center.rounded-circle.p-2.mr-10.payment-confirm__icon
					img(src="/assets/img/icons/check.svg")
				p.m-0.b3(v-if="!typeNeuro") {{ `${$t('plan.freePeriodsSelected.' + plan.free.title)}` }}
				p.m-0.b3(v-if="typeNeuro") {{ `${$t('plan.freePeriodsSelected.3days')}` }}

			.payment-confirm__items
				p.b1.payment-confirm__item
					span.text-grey(v-html="`${$t('h1.project')}:&nbsp;`")
					span {{ project.title }}
				p.b1.payment-confirm__item(v-if="!typeNeuro")
					span.text-grey.text-nowrap(v-html="`${$t('project.plan')}:&nbsp;`")
					span.overflow-wrap {{ plan.title }}

				//- Для нейроботов (но не токенов)
				p.b1.payment-confirm__item(v-if="typeNeuro && !typeBuyTokens")
					span.text-grey.text-nowrap(v-html="`${$t('project.plan')}:&nbsp;`")
					span.overflow-wrap(v-if="plan.title != 'Quarterly'") {{ plan.title != 'Monthly' ? $t('date.year') : $t('date.month') }}
					span.overflow-wrap(v-else-if="plan.title == 'Quarterly'") {{ $t('date.threeMonths') }}
					span.tariffs__discount.payment-confirm__discount--year(v-if="plan.title != 'Monthly' && plan.title != 'Quarterly'") {{ discountNeuro }}

				//- Для токенов
				p.b1.payment-confirm__item(v-if="typeBuyTokens")
					span.text-grey.text-nowrap(v-html="`${$t('project.product')}:&nbsp;`")
					span.overflow-wrap {{ product.name }}
					span.tariffs__discount.payment-confirm__discount(v-if="product.discount" :class="{'is-green': product.colorDiscount == 'green'}") -{{ product.discount }}

				p.d-flex.align-items-md-center.b1.mb-1(v-if="promocode")
					span.text-grey(v-html="`${$t('paymentConfirm.promoCode')}:&nbsp;`")
					span {{ promocode.code }}
					span.b4.ml-2.payment-confirm__discount -{{ promocode.discount }}%
				p.b1.payment-confirm__item(v-if="promotion")
					span.text-grey(v-html="`${$t('paymentConfirm.promotion')}:&nbsp;`")
					span.overflow-wrap {{ promotion.title }}
					span.b4.ml-2.payment-confirm__discount.payment-confirm__discount--promotion -{{ promotion.discount }}%
				p.b1.payment-confirm__item
					span.text-grey(v-html="`${$t('forms.price')}:&nbsp;`")
					span(v-if="!promocode && !promotion && !typeBuyTokens && plan.title != 'Quarterly'" v-html="`${totalPrice} ${$t('plan.periods.' + plan.period.title)}`")
					span(v-if="!promocode && !promotion && !typeBuyTokens && plan.title == 'Quarterly'" v-html="`${totalPrice} ${$t('date.inThreeMonths')}`")
					span(v-if="promocode || promotion" v-html="`${getDiscountWithCurrency()}`")
					span.text-grey.text-through.ml-2(v-if="promocode || promotion" v-html="plan.price.priceWithCurrency()")
					span(v-if="promocode || promotion" v-html="` ${$t('plan.periods.' + plan.period.title)}`")
					span(v-if='!typeBuyTokens && isRussia && !plan.isCurrencyRUB() && !typeNeuro') {{ $t('forms.priceInRUB')}}
					span(v-if='!isRussia && !typeNeuro')
						span(v-if='isAED') {{ $t('social.hPaymentPriceAedMamo') }}
						span(v-else-if='plan.isCurrencyRUB()') {{ $t('social.hPaymentPriceRubMamo') }}
					b.text-grey.payment-confirm__price-old(v-if="typeNeuro && !typeBuyTokens && plan.title == 'Annual'" v-html="plan.priceFull.priceWithCurrency()")
					span(v-if="typeBuyTokens" v-html="product.cost")
					b.text-grey.payment-confirm__price-old(v-if="typeBuyTokens && product.oldCost" v-html="product.oldCost")

			.d-flex.flex-column.payment-confirm__info(v-if="isTrial")
				p.b3.m-0(v-if="!typeNeuro" v-html="`${descTrial}${isMobile ? ' ' : '<br>'}<b class='text-bold'>${$t('social.hPaymentConfirmExtendSubscription3')}</b>`")

				//- Для нейроботов
				p.b3.m-0(v-if="typeNeuro && loginNeuro" v-html="`${descTrialNeuro}<br><b class='text-bold'>${$t('social.hPaymentConfirmExtendSubscription4')}</b><a href=${generateTelegramLink(loginNeuro)} target='_blank'>@${loginNeuro}</a><b class='text-bold'>${$t('social.hPaymentConfirmExtendSubscription5')}</b>`")

				//p.b3(v-html="`${$t('paymentConfirm.freeText[0]')} ${trialUntil}. ${$t('paymentConfirm.freeText[1]')} ${plan.price.priceWithCurrency()} ${$t('plan.periods.' + plan.period.title)} ${$t('paymentConfirm.freeText[2]')}${isMobile ? ' ' : '<br>'}<b>${$t('social.hPaymentConfirmExtendSubscription3')}</b>`")

			.d-flex.flex-column.payment-confirm__donat(v-if="!isTrial && project.isAllowedExtraPrice && !sumrcm && !typeNeuro")
				p.b3.payment-confirm__donat-txt {{ $t('paymentConfirm.donat') }}
				donate-form(:plan='plan' @onChange='setDonate')

			p.position-relative.payment-confirm__warning.payment-confirm__terms(v-if='!isTrial && !typeBuyTokens')
				span(v-if='isRussia')
					span(v-if='plan.isCurrencyRUB()') {{ $t('social.hPaymentConfirmExtendSubscription') }}
					span(v-else) {{ $t('social.hPaymentConfirmExtendSubscription1') }}
				span(v-else)
					span(v-if='plan.isCurrencyEUR()') {{ $t('social.hPaymentConfirmExtendSubscription') }}
					span(v-else) {{ $t('social.hPaymentConfirmExtendSubscription2') }}
				b.text-bold(v-if="!typeNeuro" v-html="`<br>${$t('social.hPaymentConfirmExtendSubscription3')}`")
				template(v-if="typeNeuro")
					b.text-bold(v-html="`<br>${$t('social.hPaymentConfirmExtendSubscription4')}`")
					a(:href="generateTelegramLink(loginNeuro)" target='_blank') @{{ loginNeuro }}
					b.text-bold {{ $t('social.hPaymentConfirmExtendSubscription5') }}
			p.position-relative.payment-confirm__warning(v-else-if='isTrial') {{ $t('paymentConfirm.freeWarning') }}

			my-input.payment-confirm__email(
				v-if="!isTrial"
				v-model="email"
				:label="$t('errors.required.email')"
				:errorFeedback="email == '' && errorEmail ? $t('errors.required.email2') : ''"
				:icon="'icon-sms'"
				:classInput="{'is-error': errorEmail}"
			)
			.text-danger.b4.mb-3.payment-confirm__error(v-if="errorEmail && email.length > 0") {{ $t('errors.valid.email') }}

			rules-confirm.payment-confirm__rules(
				v-if="!isTrial"
				:showOnlyRuDocs='country != "ru"'
				:isPlanForever='typeBuyTokens ? null : plan.isForever()'
				@isChecked="confirmRulesChaned"
				:hadCheckedOnce="checkedRules"
			)

			.btn.btn-primary.mt-auto.payment-confirm__btn(v-if="!isTrial && !typeNeuro" @click="sendPayment" :class="{'disabled': isDisabled && isloading}")
				.loader(v-if="isloading")
				span(v-else) {{ $t('forms.pay') }}
			.btn.btn-primary.mt-auto.payment-confirm__btn(v-if="isTrial && !typeNeuro" @click="step = 2") {{ $t('wizard.continue') }}

			//- Для нейроботов
			.btn.btn-primary.mt-auto.payment-confirm__btn(v-if="!isTrial && typeNeuro" @click="sendPayment" :class="{'disabled': isDisabled && isloading, 'payment-confirm__purple': !typeBuyTokens && plan.title != 'Monthly'}")
				.loader(v-if="isloading")
				span(v-else) {{ $t('forms.pay') }}
			.btn.btn-primary.mt-auto.payment-confirm__btn(v-if="isTrial && typeNeuro" @click="step = 2") {{ $t('wizard.continue') }}

		.d-flex.flex-column.h-100(v-if="step == 2")
			.d-flex.flex-column.payment-confirm__info.payment-confirm__indication
				p.b3.m-0(v-html="country == 'en' ? descTrialEnNeuro : $t('forms.trialPaymentDesc')")

			my-input.payment-confirm__email(
				v-model="email"
				:label="$t('errors.required.email')"
				:errorFeedback="email == '' && errorEmail ? $t('errors.required.email2') : ''"
				:icon="'icon-sms'"
				:classInput="{'is-error': errorEmail}"
			)
			.text-danger.b4.mb-3.payment-confirm__error(v-if="errorEmail && email.length > 0") {{ $t('errors.valid.email') }}

			rules-confirm.payment-confirm__rules(:showOnlyRuDocs='country != "ru"' :isPlanForever='plan.isForever()' @isChecked="confirmRulesChaned" :hadCheckedOnce="checkedRules")

			.btn.btn-primary.mt-auto.payment-confirm__btn(@click="sendPayment" :class="{'disabled': isDisabled && isloading}")
				.loader(v-if="isloading")
				span(v-else) {{ $t('wizard.continue') }}
</template>

<script>
import MyInput from '@/components/UI/MyInput';
import DonateForm from '@/components/Form/Donate';
import RulesConfirm from '@/components/Form/RulesConfirm';
import { mapActions, mapGetters } from 'vuex';
import { Money, CURRENCY } from '@/models/money';
import moment from 'moment-timezone';
import { SERVICE_STRING } from '@/utils/string';

export default {
    name: 'PaymentConfirm',
    components: {
        MyInput,
        DonateForm,
        RulesConfirm,
    },
    props: {
        plan: {
            type: Object,
            default: () => ({}),
        },
        isTrial: {
            type: Boolean,
            default: false,
        },
        promocode: {
            type: Object,
            default: () => ({}),
        },
        promotion: {
            type: Object,
            default: () => ({}),
        },
        country: {
            type: String,
            default: '',
        },
        sumrcm: {
            type: Object,
            default: () => ({}),
        },
        typeNeuro: {
            type: Boolean,
            default: false,
        },
        loginNeuro: {
            type: String,
            default: '',
        },
        typeBuyTokens: {
            type: Boolean,
            default: false,
        },
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        donate: null,
        email: '',
        errorEmail: false,
        isDisabled: true,
        isCheckedRules: false,
        checkedRules: false,
        step: 1,
        isMamoAed: true,
    }),
    computed: {
        ...mapGetters({
            project: 'project/opened',
            isloading: 'users/isPending',
        }),
        totalPrice() {
            if (this.sumrcm) {
                return (this.sumrcm.amount / 100).toFixed(2).replace('.00', '') + ' ' + CURRENCY[this.sumrcm.currency];
            } else {
                if (this.typeNeuro) {
                    return (
                        (this.donate
                            ? this.donate + Number(this.plan.price.price())
                            : this.thousandsSeparator(+this.plan.price.price())) +
                        ' ' +
                        this.plan.price.currencyTitle()
                    );
                }
                return (
                    (this.donate ? this.donate + Number(this.plan.price.price()) : this.plan.price.price()) +
                    ' ' +
                    this.plan.price.currencyTitle()
                );
            }
        },
        trialUntil() {
            if (this.plan.isTrial && this.plan.free)
                return moment().add(parseInt(this.plan.free.title), 'days').format('D MMMM YYYY');

            return '';
        },
        descTrial() {
            if (this.plan.price.isCurrencyRUB() && this.plan.period.title == 'forever') {
                return this.$t('forms.priceAfterTrial')
                    .replace('%until%', this.trialUntil)
                    .replace('%price%', this.plan.price.priceWithCurrency())
                    .replace('%period%', this.$t('plan.periods.' + this.plan.period.title));
            } else if (!this.plan.price.isCurrencyRUB() && this.plan.period.title == 'forever') {
                return this.$t('forms.priceAfterTrial1')
                    .replace('%until%', this.trialUntil)
                    .replace('%price%', this.plan.price.priceWithCurrency())
                    .replace('%period%', this.$t('plan.periods.' + this.plan.period.title));
            } else if (this.plan.price.isCurrencyRUB() && this.plan.period.title != 'forever') {
                return this.$t('forms.priceAfterTrial2')
                    .replace('%until%', this.trialUntil)
                    .replace('%price%', this.plan.price.priceWithCurrency())
                    .replace('%period%', this.$t('plan.periods.' + this.plan.period.title));
            } else if (!this.plan.price.isCurrencyRUB() && this.plan.period.title != 'forever') {
                return this.$t('forms.priceAfterTrial3')
                    .replace('%until%', this.trialUntil)
                    .replace('%price%', this.plan.price.priceWithCurrency())
                    .replace('%period%', this.$t('plan.periods.' + this.plan.period.title));
            } else {
                return '';
            }
        },
        descTrialEnNeuro() {
            return this.$t('forms.trialPaymentDescEnNeuro').replace('%period%', this.trialUntil);
        },
        descTrialNeuro() {
            return this.$t('forms.priceAfterTrial2')
                .replace('%until%', this.trialUntil)
                .replace('%price%', this.plan.price.priceWithCurrency())
                .replace('%period%', this.$t('plan.period3months'));
        },
        isRussia() {
            return this.country == 'ru';
        },
        isAED() {
            if (this.isRussia) return false;
            if (!this.isMamoAed) return false;

            if (this.plan.price.isCurrencyRUB() && parseFloat(this.totalPrice) >= 200) return false;
            if (
                (Money.isCurrencyUSD(this.plan.price.currency) || Money.isCurrencyEUR(this.plan.price.currency)) &&
                parseFloat(this.totalPrice) >= 2
            )
                return false;

            return true;
        },
    },
    methods: {
        ...mapActions({
            paymentInit: 'users/paymentInit',
        }),
        async sendPayment() {
            if (this.email == '' || (this.email.length > 0 && !SERVICE_STRING.isValidEmail(this.email))) {
                this.errorEmail = true;
            } else {
                this.errorEmail = false;
            }

            if (!this.isCheckedRules) {
                this.checkedRules = true;
            } else {
                this.checkedRules = false;
            }

            if (this.isCheckedRules == true && this.errorEmail == false) {
                this.$emit('onConfirm', { donate: this.donate, email: this.email });
            }
        },
        thousandsSeparator(value) {
            if (typeof value !== 'number') {
                return value;
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        setDonate(v) {
            this.donate = v;
            this.getPaymentSystem();
        },
        getDiscountWithCurrency() {
            if (this.plan.price.isCurrencyRUB()) {
                return (
                    (this.donate ? this.donate : 0) +
                    Math.round(
                        (this.plan.price.price() / 100) *
                            (100 - (this.promotion ? this.promotion.discount : this.promocode.discount))
                    ) +
                    ' ' +
                    this.plan.price.currencyTitle()
                );
            } else {
                return (
                    (
                        (this.donate ? this.donate : 0) +
                        (this.plan.price.price() / 100) *
                            (100 - (this.promotion ? this.promotion.discount : this.promocode.discount))
                    )
                        .toFixed(2)
                        .replace('.00', '') +
                    ' ' +
                    this.plan.price.currencyTitle()
                );
            }
        },
        confirmRulesChaned(v) {
            this.isCheckedRules = v;

            if (!this.isCheckedRules) {
                this.checkedRules = true;
            } else {
                this.checkedRules = false;
            }
        },
        getPaymentSystem() {
            let params = {
                isForeignCard: !this.isRussia,
                returnGateway: true,
                planId: this.plan.id,
                isTrial: this.isTrial,
                extraPriceAmount: this.donate == 0 ? null : this.donate * 100,
            };

            if (!this.isRussia)
                return this.paymentInit(params).then(v => {
                    this.isMamoAed = Money.isCurrencyAED(v.price.currency);
                });
        },
        generateTelegramLink(loginNeuro) {
            return `https://t.me/${this.loginNeuro}`;
        },
    },
    watch: {
        email() {
            if (this.email.length > 0 && !SERVICE_STRING.isValidEmail(this.email.trim())) {
                this.email = this.email.replace(' ', '');
                this.errorEmail = true;
            } else {
                this.email = this.email.replace(' ', '');
                this.errorEmail = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.payment-confirm {
    &__trial {
        padding: 9.5px 22px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--brand-bg-success);
        margin-bottom: 25px;

        @include respond-below(sm) {
            margin-bottom: 20px;
        }
    }
    &__icon {
        max-width: 35px;
        min-width: 35px;
        max-height: 35px;
        min-height: 35px;
        background-color: var(--foreground-back);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    }

    &__discount {
        display: inline-flex;
        height: 21px;
        padding: 1.5px 6px;
        color: var(--foreground-color);
        background: var(--brand-color-warning);
        border-radius: 6px;
        display: inline-block;

        &--promotion {
            background: var(--brand-bg-gradient-purple);
        }

        &--year {
            padding: 3.5px 6.25px;
            color: var(--foreground-color) !important;
        }

        &.is-green {
            background: var(--brand-success);
        }
    }

    &__donat {
        padding: 16px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--brand-bg-primary);
        margin-top: 26px;

        @include respond-below(sm) {
            margin-top: 20px;
            margin-bottom: 1px;
        }

        &-txt {
            margin-bottom: 12px;

            @include respond-below(sm) {
                margin-bottom: 10.5px;
            }
        }
    }

    &__info {
        padding: 16px;
        border-radius: var(--border-radius-rounded);
        background-color: rgba(#52a7f9, 0.1);
        margin-top: 26px;

        @include respond-below(sm) {
            margin-top: 20px;
        }
    }

    &__indication {
        margin-top: -1px;
    }

    &__warning {
        padding: 0 0 0 34px;
        font-size: 14px;
        line-height: 22px;
        margin-top: 25px;
        margin-bottom: 25px;

        @include respond-below(sm) {
            margin-top: 19px;
            margin-bottom: 20px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            mask-image: url('/assets/img/icons/info-square.svg');
            background-color: var(--brand-primary);
            background-repeat: no-repeat;
        }
    }

    &__terms {
        margin-bottom: 0;
    }

    &__error {
        margin-top: -12px !important;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--brand-success) !important;
        border-color: var(--brand-success) !important;
    }

    &__btn {
        color: var(--foreground-color) !important;
    }

    &__purple {
        background: linear-gradient(114.44deg, #624af2 0%, #bd64eb 100%);
        color: var(--brand-color-discount-year) !important;

        &:active {
            background: linear-gradient(260.48deg, #8977f9 5.07%, #d18bf5 98.17%) !important;
        }
    }

    &__price {
        &-old {
            position: relative;
            margin-left: 6px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                background: center / contain no-repeat
                    url("data:image/svg+xml,%3Csvg width='58' height='14' viewBox='0 0 58 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12.5L56.5 1' stroke='%23FF7C7C' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__items {
        margin-bottom: -5px;
    }

    &__item {
        margin-bottom: 5px;
    }

    &__email {
        margin-top: 25px;
        margin-bottom: 23.5px;

        @include respond-below(sm) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &__rules {
        margin-bottom: 12px !important;

        @include respond-below(sm) {
            margin-bottom: 7px !important;
        }
    }
}
</style>
