<template lang="pug">
	.d-flex.flex-column.flex-sm-row.justify-content-between.align-items-sm-center.card-payment.tariff-card(v-if="card" :class="{'tariff-card--free': isTrial}")
		.d-flex.flex-column
			span.b1.text-bold.tariff-card__title(v-if="isTrial") {{ $t('plan.free') }}
			p.mb-0.d-flex(v-if="isTrial")
				span.tariff-card__amount.text-primary.text-bold.mb-0.text-bold {{ $t('plan.freePeriods.' + card.free.title) }}
				span.d-block.text-primary.text-bold.border-rounded.tariff-card__free {{ $t('forms.free') }}

			span.b1.overflow-wrap.text-bold.tariff-card__title(v-if="card.title && !isTrial") {{ card.title }}
			p.b3.mb-0(v-if="!isTrial")
				span.tariff-card__amount.text-primary.text-bold(v-html="card.price.priceWithCurrency()")
				span.text-grey &nbsp; {{ $t('plan.periods.' + card.period.title) }}

		.btn.btn-medium.tariff-card__button.btn-primary(
			@click="$emit('click', $event)"
		) {{ !isTrial ? $t('forms.pay') : $t('forms.try') }}
</template>

<script>
export default {
    name: 'TariffCard',
    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
        isTrial: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.tariff-card {
    padding: 19px 20px;
    margin-bottom: 10px;

    @include respond-above(xs) {
        margin-bottom: 15px;
    }

    &--free {
        background-color: var(--brand-bg-primary) !important;
        box-shadow: none !important;
    }

    &__title {
        margin-bottom: 12px !important;

        @media (min-width: 576px) {
            margin-bottom: 6px !important;
        }
    }

    &__amount {
        font-size: 24px;
        line-height: 28px;
    }

    &__free {
        background-color: var(--foreground-color);
        margin-left: 11px;
        padding: 5px 15px 7px;
        font-size: 12px;

        @media (min-width: 576px) {
            margin-left: 16px;
        }
    }

    &__button {
        min-width: 175px;
        margin-top: 20px;

        @media (min-width: 576px) {
            margin-top: 0;
        }
    }
}
</style>
