<template lang="pug">
	.d-flex.flex-column.overflow-hidden.border-rounded.project-short(:class="[contentClass, {'dashboard-row': !expand && contentClass == 'project-short--dashboard' && isMobile}]")
		img.w-100(v-if='item.image' :src='item.image.contentUrl' :alt='item.title')
		.d-flex.flex-column.project-short__info
			h3.mb-2 {{ item.title }}
			.project-short__text-wrap(:class="{'expand-text': expand}")
				p.b3.m-0.project-short__text
					span(ref="paymentText" v-html="description")
			.d-flex.mt-2.project-short__inner
				.btn.btn-expand.mr-2(v-if="paymentTextHeight > 67 || (contentClass == 'project-short--dashboard' && isMobile)" @click="doExpand" :class="{'rotate': expand}") {{ !expand ? $t('forms.expand') : $t('forms.collapse') }}
				i.fontello-btn.fontello-btn-primary.fontello-icon-setting(v-if="contentClass == 'project-short--dashboard'" @click="$router.push({name: 'project_settings', params: {id: item.id}, query: {tab: 1, plan: id}})")
				a(:href='paymentLinkDefault' target='_blank')
					i.fontello-btn.fontello-btn-primary.fontello-icon-link.ml-2(v-if="contentClass == 'project-short--dashboard' && item.isActive()")
</template>

<script>
export default {
    name: 'ProjectCardShort',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        contentClass: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        expand: false,
        paymentTextHeight: null,
    }),
    computed: {
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
        description() {
            if (!this.item || !this.item.description) return '';

            let desc = this.item.description.replace(
                /(https?\:\/\/[^\s]*)/gi,
                '<a class="text-primary" target="_blank" href="$1">$1</a>'
            );

            return desc;
        },
    },
    mounted() {
        if (this.$refs.paymentText) this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
        window.addEventListener('resize', this.resizeHandler);
    },
    methods: {
        doExpand() {
            this.expand = !this.expand;
            this.$emit('onExpanded', this.expand);
        },
        resizeHandler() {
            if (this.$refs.paymentText) this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.project-short {
    height: max-content;
    box-shadow: var(--card-box-shadow);
    margin-bottom: 10px;

    @include respond-above(xs) {
        margin-bottom: 20px;
    }

    &__info {
        padding: 20px;
        background-color: var(--grayscale-dark-white-back);
    }

    &__text-wrap {
        max-height: 67px;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

        .project-short__text {
            word-wrap: break-word;
            white-space: break-spaces;
        }

        &.expand-text {
            max-height: 200rem;
            transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

            .project-short__text {
                animation: open3 0.1s linear 0s forwards;

                @media (min-width: 576px) {
                    animation: open4 0.1s linear 0s forwards;
                }
            }
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        animation: close3 0.1s linear 0.1s forwards;

        @media (min-width: 576px) {
            animation: close4 0.1s linear 0.1s forwards;
        }
    }

    @keyframes open3 {
        from {
            -webkit-line-clamp: 3;
        }
        to {
            -webkit-line-clamp: initial;
        }
    }

    @keyframes open4 {
        from {
            -webkit-line-clamp: 4;
        }
        to {
            -webkit-line-clamp: initial;
        }
    }

    @keyframes close3 {
        from {
            -webkit-line-clamp: initial;
        }
        to {
            -webkit-line-clamp: 3;
        }
    }

    @keyframes close4 {
        from {
            -webkit-line-clamp: initial;
        }
        to {
            -webkit-line-clamp: 4;
        }
    }

    .fontello-btn {
        width: 30px;
        height: 30px;
        padding: 7px;
    }

    &.dashboard-row {
        @include respond-below(sm) {
            flex-direction: row !important;
            padding: 16px;

            img {
                width: 95px !important;
                height: 53px;
                object-fit: cover;
                border-radius: 6px;
                margin-right: 10px;
            }

            .project-short__info {
                padding: 0;
            }

            h3 {
                font-size: 14px !important;
                line-height: 22px !important;
                margin-bottom: 5px !important;
            }

            .project-short__text-wrap {
                display: none;
            }

            .project-short__inner {
                margin: 0 !important;
            }
        }
    }

    &--dashboard {
        background-color: var(--foreground-color);
        margin-bottom: 20px !important;

        .btn-expand {
            font-size: 12px;
            padding: 7px 23px 7px 10px;

            &::before {
                top: 9px;
                right: 7px;
                width: 12px;
                height: 12px;
            }
        }

        .fontello-icon-setting {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            font-size: 16px;
        }

        .fontello-icon-link {
            display: block;
            padding: 0;
            background-color: transparent;
            transform: rotate(-45deg);

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }
    }
}
</style>
