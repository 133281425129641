<template lang="pug">
	b-modal(id="complain" ref='modal' centered content-class="payment-page__add-promo" header-class="d-flex p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1 {{ $t('paymentPage.complainProject') }}
			icon-close(@click="close()")
		span.b3.mb-3.text-grey {{ $t('paymentPage.complainDec') }}
		a.text-decoration-none.mb-3(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
			pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
		pw-btn-mail
</template>

<script>
import IconClose from '@/components/Common/IconClose';
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';

export default {
    name: 'ModalProjectComplain',
    components: {
        IconClose,
        PwBtn,
        PwBtnMail,
    },
};
</script>
