<template lang="pug">
	button.d-flex.justify-content-between.align-items-center.cursor-pointer.position-relative.pw-btn-payment(
		v-if="text"
		:class="{'pw-btn-payment--dark' : isDarkTheme, 'pw-btn-payment--disabled': isDisabled}"
		:disabled="isDisabled"
	)
		span.b3.mr-1.pw-btn-payment__txt(v-html="text")
		svg.ml-2.pw-btn-payment__arrow(width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg")
			path(d="M10.7129 3.75L15.7507 9L10.7129 14.25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
			line(x1="14.5488" y1="9.27344" x2="3.24903" y2="9.27344" stroke-width="2" stroke-linecap="round")

</template>

<script>
import { defaultColor } from '@/constants/config';

export default {
    name: 'PwBtnPayment',
    props: {
        text: {
            default: '',
            type: String,
        },
        isDisabled: {
            default: false,
            type: Boolean,
        },
    },
    data: () => ({
        isDarkTheme: false,
    }),
    created() {
        const color = this.getThemeColor();
        this.isDarkTheme = color.indexOf('dark') > -1;
    },
    methods: {
        getThemeColor() {
            return localStorage.getItem('themeColorPaymentPage')
                ? localStorage.getItem('themeColorPaymentPage')
                : defaultColor;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.pw-btn-payment {
    padding: 14px 25px;
    color: var(--main-text-color);
    background-color: var(--light-primary);
    border-radius: var(--border-radius-rounded);
    transition: var(--animation-time-short);
    border: none;

    &--disabled {
        cursor: default !important;
        background-color: var(--background-color);

        &:hover,
        &:active {
            background-color: var(--background-color) !important;
            box-shadow: none !important;
        }

        .pw-btn-payment__txt {
            color: var(--brand-color-disabled-txt);
        }

        .pw-btn-payment__arrow {
            stroke: var(--brand-color-disabled-arrow);
        }
    }

    svg {
        path,
        line {
            transition: var(--animation-time-short);
        }
    }

    &:hover,
    &:active {
        background-color: var(--foreground-color);
        box-shadow: var(--btn-box-shadow);
    }

    span {
        font-size: 16px !important;
        line-height: 21px !important;
    }

    &__arrow {
        stroke: var(--brand-primary);
    }
}
</style>
