<template lang="pug">
	.d-flex.align-items-start(v-if='plan && plan.id')
		span.payment-confirm__sum(v-for='i in list' @click="setValue(i)" :class="{'active-sum': value == i && (valueInput == '' || valueInput == null)}" v-html="`${i} ${plan.price.currencyTitle()}`")

		.w-100
			input.payment-confirm__input(
				v-model="valueInput"
				v-mask="plan.isCurrencyRUB() ? '####' : '###'"
				:class="{'active-input': valueInput != '' && valueInput != null, 'is-error' : error}"
				:placeholder="!isMobile ? $t('paymentConfirm.enter[0]') : $t('paymentConfirm.enter[1]')"
				@input='isValid()'
				@blur='isValid(), value = null'
			)
			.text-danger.b4.mt-1(v-if="error" v-html='error')
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'DonateForm',
    components: {},
    props: {
        plan: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        value: null,
        valueInput: null,
        error: null,
        list: [],
    }),

    computed: {
        ...mapGetters({}),
    },
    methods: {
        ...mapActions({}),
        setList() {
            let price = this.plan.price.price();

            if (this.plan.isCurrencyRUB()) {
                if (price < 500) return (this.list = [25, 50, 100]);
                if (price < 1500) return (this.list = [100, 250, 500]);
                if (price < 2500) return (this.list = [350, 750, 1500]);
                if (price < 5000) return (this.list = [500, 750, 1500]);

                return (this.list = [500, 750, 1500]);
            }
            if (this.plan.isCurrencyUSD() || this.plan.isCurrencyEUR()) {
                if (price < 50) return (this.list = [1, 5, 10]);
                if (price < 150) return (this.list = [10, 25, 50]);
                if (price < 250) return (this.list = [15, 30, 50]);
                if (price <= 500) return (this.list = [25, 50, 100]);

                return (this.list = [50, 100, 150]);
            }
        },
        setValue(v) {
            if (v == this.value) {
                this.value = null;
                this.valueInput = null;
                return this.$emit('onChange', null);
            }

            this.value = v;
            this.valueInput = null;
            this.$emit('onChange', v);
        },
        isValid() {
            this.error = null;

            if (this.valueInput == null || this.valueInput.length == 0) return this.$emit('onChange', null);

            if (this.plan.isCurrencyRUB() && (this.valueInput < 1 || this.valueInput > 9999)) {
                this.valueInput = 9999;
                this.value = 9999;
                this.$emit('onChange', this.value);
                return (this.error = this.$t('paymentConfirm.donationValues.rub'));
            }
            if (this.plan.isCurrencyUSD() && (this.valueInput < 1 || this.valueInput > 199)) {
                this.valueInput = 199;
                this.value = 199;
                this.$emit('onChange', this.value);
                return (this.error = this.$t('paymentConfirm.donationValues.usd'));
            }
            if (this.plan.isCurrencyEUR() && (this.valueInput < 1 || this.valueInput > 199)) {
                this.valueInput = 199;
                this.value = 199;
                this.$emit('onChange', this.value);
                return (this.error = this.$t('paymentConfirm.donationValues.eur'));
            }

            this.$emit('onChange', Number(this.valueInput).toFixed(2) * 1);
        },
    },
    created() {
        this.setList();
    },
    destroyed() {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.payment-confirm {
    &__sum {
        padding: 6.5px 9.5px 5.5px;
        font-size: 14px;
        line-height: 18px;
        color: var(--brand-primary);
        background-color: var(--foreground-color);
        border-radius: var(--border-radius-rounded);
        border: 1px solid var(--brand-primary);
        margin-right: 12px;
        transition: var(--animation-time-short);
        white-space: nowrap;
        cursor: pointer;

        &.active-sum {
            color: var(--background-color);
            background-color: var(--brand-primary);
        }
    }

    &__input {
        width: 100%;
        padding: 7px 6px 7px;
        color: var(--main-text-color);
        border-radius: var(--border-radius-rounded);
        background-color: var(--foreground-color);
        transition: var(--animation-time-short);
        border: 1px solid transparent;
        text-align: center;

        &::placeholder {
            font-size: 14px;
            line-height: 22px;
            color: var(--grey-text-color);
            text-align: center;
        }

        &::-moz-placeholder {
            text-align: center;
        }

        &:-moz-placeholder {
            text-align: center;
        }

        &:-ms-input-placeholder {
            text-align: center;
        }

        &::-webkit-input-placeholder {
            text-align: center;
        }

        &:focus,
        &:hover {
            border: 1px solid var(--brand-primary);
        }

        &.active-input {
            color: var(--background-color);
            background-color: var(--brand-primary);
            border: 1px solid var(--brand-primary);

            &:focus {
                color: var(--main-text-color);
                background-color: var(--foreground-color);
            }
        }
        &.is-error {
            border: 1.5px solid var(--red-bg-color) !important;

            &:hover,
            &:focus {
                border: 1px solid var(--red-bg-color) !important;
            }
        }
    }
}
</style>
