<template lang="pug">
	b-modal(id="payment-country" ref='modal' centered :content-class="['payment-page__payment-country']" header-class="d-flex p-0 border-0 mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			h3(:class="{'b1': !isMobile}") {{ $t('finance.chouseCountryIssue') }}
			icon-close.mt-2(@click="close()")
		pw-btn-payment.mb-10(:text="isWindows ? $t('finance.russiaWithFlag') : $t('finance.russiaCountry')" @click.native="select('ru')")
		pw-btn-payment(:text="$t('finance.anotherCountry')" @click.native="select('en')" :isDisabled="isTrial")

		.mt-10.position-relative.border-rounded.select-country__warning(v-if="isTrial")
			span.b4.text-grey {{ $t('finance.availableOnlyRF') }}

</template>

<script>
import IconClose from '@/components/Common/IconClose';
import PwBtnPayment from '@/components/Buttons/PwBtnPayment';

export default {
    name: 'ModalSelectCountry',
    components: {
        IconClose,
        PwBtnPayment,
    },
    props: {
        isTrial: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        select(v) {
            this.$emit('onSelect', v);
            this.$bvModal.hide('payment-country');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.select-country__warning {
    padding: 13px 15px 13px 56px;
    background-color: var(--brand-bg-warning);

    @include respond-below(sm) {
        padding: 13px 15px 13px 56px;
        margin-top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: 19px;
        width: 24px;
        height: 24px;
        background: url('/assets/img/icons/info-square.svg');
        background-repeat: no-repeat;

        @include respond-below(sm) {
            left: 20.5px;
        }
    }
}
</style>
